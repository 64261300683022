<template>
  <div>
    <sm-editable-item
      v-model="form"
      :controllerName="controllerName"
      :breadcrumbs="breadcrumbs"
      :pageHeader="pageHeader"
      :fields="fields"
      :isLoadingSaveButton="isLoadingSaveButton"
      @save="onSave('Customers')"
      @cancel="onCancel('Customers')"
    >
      <!-- <template #createDbButton>
        <sm-button
          class="create-db-button"
          :isLoading="isLoadingDbButton"
          @click="onOpenCreateDbModal"
        >
          Создать базу
        </sm-button>
      </template>
<template #createDemoDbButton>
        <sm-button
          class="create-db-button"
          :isLoading="isLoadingDbButton"
          @click="onOpenCreateDemoDbModal"
        >
          Создать демо-базу
        </sm-button>
      </template> -->
      <template #getKppButton="{ field }">
        <sm-button
          class="get-kpp-button"
          :is-loading="clientInfo.isLoading"
          :disabled="field.disabled"
          @click="getKpp"
        >Получить КПП</sm-button>
      </template>
    </sm-editable-item>
    <!-- <sm-form-modal
      v-model="modalForm"
      :fields="modalFields"
      :show="showCreateDbModal"
      :modal-title="modalCreateDbTitle"
      :disabledSaveButton="!modalForm.databaseName"
      :isLoadingSaveButton="isLoadingDbButton"
      @close="onCancelCreateDb"
      @save="onConfirmCreateDb"
      @cancel="onCancelCreateDb"
    /> -->
    <!-- <sm-form-modal
      v-model="modalDemoForm"
      :fields="modalDemoFields"
      :show="showCreateDemoDbModal"
      :modal-title="modalCreateDemoDbTitle"
      :isLoadingSaveButton="isLoadingDbButton"
      @close="onCancelCreateDemoDb"
      @save="onConfirmCreateDemoDb"
      @cancel="onCancelCreateDemoDb"
    >
      <template v-slot:demoCode="{ field }">
        <div v-show="modalDemoForm.demoCode" class="create-db__fields-wrap">
          <div class="create-db__field">
            <p class="editable-form__description">{{ field.label }}</p>
            <sm-input v-model="modalDemoForm.demoCode" :type="field.type" />
          </div>
          <div class="create-db__field create-db__field--center">
            <sm-button
              :isLoading="isLoadingDbButton"
              class="create-db__copy-button"
              @click="onCopyDemoCode"
              >Копировать код</sm-button
            >
          </div>
        </div>
      </template>
    </sm-form-modal> -->
  </div>
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('editableList');
// const { mapActions: mapActionsDbMaintenance } =
//   createNamespacedHelpers('dbMaintenance');
const { mapActions: mapActionsCustomers, mapState: mapStateCustomers } = createNamespacedHelpers('customers');
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';
// import SmInput from '@/components/common/forms/SmInput.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';
// import SmFormModal from '@/components/common/modals/SmFormModal.vue';
// Utils
// import { copy } from '@/utils/clipboard';

export default {
  name: 'CustomerCreate',

  components: {
    SmEditableItem,
    // SmInput,
    SmButton,
    // SmFormModal,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      controllerName: 'Customers',
      pageHeader: 'Создание клиента',
      breadcrumbs: [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Клиенты',
          route: { name: 'Customers' },
        },
        {
          text: 'Создание клиента',
        },
      ],
      initialForm: {
        name: null,
        // folder: null,
        allowHelpAeroAccess: false,
        phone: null,
        fio: null,
        email: null,
        inn: null,
        kpp: null,
      },
      form: null,
      // isLoadingDbButton: false,
      // modalCreateDbTitle: 'Создание базы данных',
      // modalCreateDemoDbTitle: 'Создать демо базы данных',
      // showCreateDbModal: false,
      // showCreateDemoDbModal: false,
      // modalForm: {
      //   databaseName: null,
      // },
      // modalDemoForm: {
      //   databaseName: null,
      //   demoCode: null,
      // },
      isLoadingKpp: false
    };
  },

  computed: {
    ...mapStateCustomers(['clientInfo']),

    fields() {
      return [
        {
          form: [
            {
              type: 'text',
              key: 'name',
              label: 'Наименование',
            },
            // {
            //   type: 'text',
            //   key: 'folder',
            //   label: 'Папка',
            // },
            // {
            //   type: 'text',
            //   key: 'createDbButton',
            // },
            // {
            //   type: 'text',
            //   key: 'createDemoDbButton',
            // },
            {
              type: 'checkbox',
              key: 'allowHelpAeroAccess',
              label: 'Разрешен вход на help.1caero.ru',
            },
            {
              type: 'text',
              key: 'phone',
              label: 'Телефон',
              attributes: {
                mask: '+7(###)### ## ##',
                placeholder: '+7(___)___ __ __'
              }
            },
            {
              type: 'text',
              key: 'fio',
              label: 'ФИО',
            },
            {
              type: 'text',
              key: 'email',
              label: 'Email',
            },
            {
              type: 'text',
              key: 'inn',
              label: 'ИНН',
            },
            {
              type: 'text',
              key: 'kpp',
              label: 'КПП',
            },
            {
              type: 'text',
              key: 'getKppButton',
              disabled: !this.form.inn || !this.form.inn.length
            }
          ],
        },
      ];
    },

    // modalFields() {
    //   return [
    //     {
    //       type: 'text',
    //       key: 'databaseName',
    //       label: 'Название базы данных',
    //     },
    //   ];
    // },

    // modalDemoFields() {
    //   return [
    //     {
    //       type: 'text',
    //       key: 'databaseName',
    //       label: 'Название базы данных',
    //     },
    //     {
    //       type: 'text',
    //       key: 'demoCode',
    //       label: 'Код демо-базы',
    //       disabled: true,
    //     },
    //   ];
    // },
  },

  methods: {
    ...mapActions(['createItem']),
    // ...mapActionsDbMaintenance(['createDatabase', 'createDemoDatabase']),
    ...mapActionsCustomers(['fetchKpp']),

    // onOpenCreateDbModal() {
    //   this.showCreateDbModal = true;
    // },

    // onOpenCreateDemoDbModal() {
    //   this.showCreateDemoDbModal = true;
    // },

    // onCancelCreateDb() {
    //   this.showCreateDbModal = false;
    //   this.modalForm.databaseName = null;
    // },

    // onCancelCreateDemoDb() {
    //   this.showCreateDemoDbModal = false;
    //   this.modalDemoForm.demoCode = null;
    // },

    // onConfirmCreateDb() {
    //   this.isLoadingDbButton = true;
    //   this.createDatabase({ ...this.modalForm, customerId: this.item.data.id })
    //     .then(() => {
    //       this.modalForm.databaseName = null;
    //       this.showCreateDbModal = false;
    //     })
    //     .finally(() => (this.isLoadingDbButton = false));
    // },

    // onConfirmCreateDemoDb() {
    //   this.isLoadingDbButton = true;
    //   this.createDemoDatabase({
    //     customerId: this.item.data.id,
    //     databaseName: this.modalDemoForm.databaseName,
    //   })
    //     .then((result) => {
    //       if (result.isSucceed) {
    //         this.$notify({
    //           header: 'Демо база данных успешно создана',
    //           type: 'success',
    //           timer: 5000,
    //         });
    //       }

    //       this.modalDemoForm.demoCode = result.demoCode;
    //     })
    //     .finally(() => (this.isLoadingDbButton = false));
    // },

    // onCopyDemoCode() {
    //   copy(this.modalDemoForm.demoCode);

    //   this.$notify({
    //     header: 'Код демо-базы скопирован',
    //     type: 'success',
    //     timer: 5000
    //   })
    // }

    getKpp() {
      if (!this.form.inn) return;

      this.fetchKpp(this.form.inn)
        .then((data) => {
          console.log('data:', data);

          if (this.clientInfo.error) {
            this.displayNotify();
          } else {
            this.form.name = data.company;
            this.form.kpp = data.kpp;
          }
        })
        .catch(() => {
          this.displayNotify();
        })
    },

    displayNotify() {
      this.$notify({
        header: 'Ошибка',
        text: 'По данному ИНН юридическое лицо не найдено',
        type: 'error',
        timer: 5000
      })
    },

    // При создании клиента переадресовывать потом на страницу редактирования, 
    // чтобы можно было создать демо-базу
    onSave(route) {
      console.log('--- onSave ---');
      console.log('pageName:', route);

      const query = {};

      this.isLoadingSaveButton = true;

      if (this.previosPageParams.previosRouteName === route) {
        query.table = JSON.stringify(
          this.previosPageParams.previosRouteQuerySort
        );
        query.filter = JSON.stringify(
          this.previosPageParams.previosRouteQueryFilter
        );
      }

      this.createItem({
        name: this.controllerName,
        payload: this.form,
      })
        .then((result) => {
          if (result.isSucceed) {
            this.$router.push({
              name: 'CustomerEdit',
              params: { id: result.id },
              query
            });

            return;
          }
          throw new Error();
        })
        .catch(() => {
          this.$notify({
            header: 'Ошибка',
            text: 'При сохраненнии данных произошла ошибка',
            type: 'error',
            timer: 5000,
          });
        })
        .finally(() => {
          this.isLoadingSaveButton = false;
        });
    }
  }
};
</script>

<style lang="scss">
.get-kpp-button {
  width: auto;
}

// .create-db-button {
//   width: 180px;
// }

// .create-db__fields-wrap {
//   display: flex;
//   flex-wrap: wrap;
//   width: 100%;
// }

// .create-db__field {
//   display: flex;
//   flex-shrink: 0;
//   width: 100%;
// }

// .create-db__field:not(:last-child) {
//   margin-bottom: 15px;
// }

// .create-db__field--center {
//   justify-content: center;
// }

// .create-db__copy-button {
//   width: auto;
// }</style>
